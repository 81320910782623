import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NowPage = () => (
  <Layout slugLine="probably stretches himself too thin." backLink={{path:'/', name: 'home'}}>
    <SEO title="Now" />
    <section className="article">
    <h2>A partial and irregularly-updated list of the things I'm spending my time on right now</h2>
    <ol>
      <li>To be perfectly honest, I don't have very many interesting side-projects or things I'm trying to learn right now. The pandemic has been tough for me in that regard—still reading and doing physical activities as much as ever, but not as much as usual by way of making or learning new things. That said,</li>
      <li>I am still writing limericks, and recently <a href="https://limericked.com" target="_blank" rel="noreferrer">made a new website for them.</a></li>
      <li>I've been reading a lot of old spy novels, Le Carré and that sort.</li>
      <li>I've also been doing a lot of very long rides on the 'ol bike</li>
      <li>I've also been working on putting my time and money where my values are when it comes to The US' systemic racial and economic inequalities and erosion of democratic norms.</li>
      <li>and of course, been spending a lot of time on Zoom.</li>
    </ol>
    <p className="article-history">
      Last updated on September 20th, 2020.
      {/*Previous versions*/} 
      {/*What is a Now page*/} 
    </p>
  </section>
  </Layout>
)

export default NowPage;
